* {
  overflow-x: hidden;
}

html {
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-color: #111111;
}

.homepage {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: 2300px;
}

.homepage * {
  overflow-y: hidden;
}

.content-area {
  background: linear-gradient(113deg, rgba(254, 110, 0, 0.35) 14.21%, rgba(254, 110, 0, 0.00) 93.02%);
  position: relative;
  height: 100vh;
  max-height: 1300px;
  width: 100%;
  z-index: 2;
  overflow-y: hidden;
  
}


.background-image {
  object-fit: cover;
  height: 100%!important;
  width: 100%;
}

.overlay {
  background-color: #000000b2;
  opacity:0.8;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.text-content {
  position: absolute;
  top: 10%;
  right: 80px;
  z-index: 3;
  height:200px;
  width:500px;
}
.avatar-image{
  opacity:0;
  width:130px;
  height:130px;
  margin-right:25px;
}

.center-shape{
  width: 287px;
  height: 485px;
  flex-shrink: 0;
  border-radius: 30px 30px 0px 0px;
  background: linear-gradient(162deg, #FE6E00 -1.86%, #DD4F00 102.09%);
  left:50%;
  bottom:0%;
  transform: translateX(-50%);
}

.show-image{
  width: 437px;
  height: 696px;
  flex-shrink: 0;
  left:50%;
  bottom:0%;
  transform: translateX(-50%);
  
}

.stats{
  width: 300px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 20px;
  background: linear-gradient(108deg, #FE6E00 1.03%, #FF5C00 106.11%);
  box-shadow: 3px 3px 2px 2px #FFF;
  padding-left:10%;
  padding-top:5%;
}

.my-num{
  color: #FFF;
  text-shadow: 5px 5px 10px #000;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom:5px;
}

.my-label{
  color: #000;
  font-family: montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.stat-box{
  display:flex;
  left:5%;
  top:5%;
  height:50%;
  width:370px;
  justify-content:space-around;
}

.project-card-cont{
  width: 256.515px;
  height: 350px;  
  border-radius: 20px;
  right:10%;
  bottom:7%;

}

.project-card{
  width:100%;
  height:100%;
  position:relative;

}

.project-card-overlay{
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.3);
  position:absolute;
}
.project-card img{
  width:100%;
  height:100%;
  position:absolute;
}
.wassup-im {
  color: #FFF;
  font-family: Patrick Hand SC;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.75px;
  opacity:0;
}

.text-wrapper {
  color: #fe6e00;
  font-family: "Poppins-ExtraBold", Helvetica;
  font-size: 50px;
  font-weight: 800;
  width:0%;
}

.i-am-a-software {
  font-family: McLaren;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 600px;
  color:#fff;
  left:6%;
  bottom:3%;
  opacity:0;
  padding:20px;
  width: 35%;
  height: 203px;
  border-radius: 10px;
  border-radius: 10px;
  background: linear-gradient(97deg, #FE6E00 13.17%, rgba(254, 110, 0, 0.00) 94.59%);
  
}

.introduction{
  opacity:0;
}

.i-am-a-passionate {
  color: #ffffff;
  font-family: "Roboto Mono", monospace;
  font-size: 30px;
  font-weight: 800;
  height: 350px;
  width: 1200px;
  opacity:1;
}


.ellipse {
  border-radius: 187.5px;
  height: 500px;
  width: 500px;
  top: 25%;
  right: 5%;
}

.ellipse-blur{
  top:35%;
  right:3%;
  width: 551px;
  height: 551px;
  fill: rgba(254, 110, 0, 0.40);
  filter: blur(125px);
  
}

.section {
  position: relative;
  padding: 20px 50px;
}

.text-wrapper-section {
  color: #fe6e00;
  font-family: 'Rowdies', sans-serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 5px;
}

.p {
  color: #ffffff;
  font-family: "Roboto Mono", Helvetica;
  font-size: 30px;
  font-weight: 700;
  width: 893px;
}

.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FE6E00;
  padding: 20px;
  text-align: center;
  border-radius: 15px;
  margin: 10px;
  width:400px;
  height: 400px;
  transition: transform 0.3s ease;
  margin-right:25px;
  z-index:10;
}

.skill-card-cont{
  overflow:visible;
}

.skill-card:hover{
  cursor:pointer;
}

.skill-card:hover .skill-icon {
  transform: scale(1.1); /* Enlarge the icon */
   /* Smooth transition for scaling */
}

@keyframes rotate-card {
  0%, 100% { transform: rotate(0deg); }
  50% { transform: rotate(-30deg); }
}


.skill-icon {
  width: 150px;
  height: auto;
  transition: transform 0.3s ease;
}

.my-skills-container {
  background-color: #fe6e00;
  padding: 20px;
  border-radius: 15px;
  width: 1150px;
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
}

.my-skills-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 30px;
}

.my-skills-icon-wrapper {
  background-color: #000000;
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 10px;
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-skills-icon {
  color: #ffffff;
  width: 60%;
  height: auto;
}

.my-skills-text {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.skill-text {
  margin-top: 10px;
  text-align: center;
  font-family: 'Rowdies', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: transparent; /* Text initially transparent */
  background-image: linear-gradient(to right, black 50%, transparent 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-position 0.5s ease;
  background-size: 200% 100%;
  background-position: 100% 0; /* Black color starts off the text */
}


.skill-card:hover .skill-text{
  background-position: 0 0;
}



.img-fluid {
  width: 100%!important;
  height: auto !important;
}

.career-background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  border-radius: 30px;
}


.end{
  margin-top:auto
}
.ml-project {
  margin-top:15px
}

.ml-project:hover{
  cursor:pointer;
}
.ml-name{
  margin-left:25px;
}
.ml-name p{
  position: relative;
  display: inline-block;
  -webkit-text-stroke: .5px white;
  font-size:40px;
  font-family: "Poppins", sans-serif;
  font-weight:800;
  letter-spacing: 3px;
  color: #000;
  background-size: 200% 100%;
  overflow:hidden
}
.ml-name p::before {
  content: attr(data-replace);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  color: white; /* Change this to your desired hover color */
  overflow: hidden;
  transition: width .5s ease-in-out;
  white-space: nowrap;
}

.ml-project:hover .ml-name p::before {
  width: 100%;
}
.ml-project .icon{
  background:#fe6e00;
  width: 70px;
  height: 70px;
  margin-bottom:10px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items: center;
}

.ml-project .icon:hover img{
  transform: scale(1.2);
  
}

.ml-project .icon img{
  width:30px;
  height:30px;
  margin:auto;
  transition: transform .2s ease-in-out ;
}




.project-container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-template-rows: repeat(2, 330px);
}

.project-container .grid-box {
  position:relative;
    border-radius: 20px;
    margin:8px;
}

.project-container {
  position: relative;
  cursor: pointer;
}

.project-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6); /* 80% opacity black background */
  color: white;
  padding: 20px;
  transition: transform 0.5s ease-in-out;
  text-align: center;
  width:100%;
  height:100%;
  transform: translateX(-100%);
}

.grid-box:hover .project-content{
  transform: translateX(0%);
}

.project-content h3 {
  margin-top:10%;
  text-align:left;
  color: #FFF;
  font-family: Rowdies;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.project-content .content-line{
  height:3px;
  width:100%;
  background-color:#FE6E00;
}
.project-content .content-desc{
  margin-top:10px;
  display:flex;
  justify-content:space-between;
}

.hori{
  width:100%!important;
  transform: translateX(-100%);
  transition:transform .3s ease-in-out;
  height:100%;
  object-fit: cover; 
  object-position: center center;
  overflow: hidden;
}
.verti{
  height:100%!important;
  width:100%;
  object-fit: cover; 
  object-position: center center;
  overflow: hidden;
  transform: translateY(-100%);
  transition:transform .3s ease-in-out;
}
.content-desc .desc{
  width:75%;
  color: #FFF;
  font-family: montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align:left;
}

.content-icon .icon{
  background:rgba(254, 110, 0, 0.50);
  width: 50px;
  height: 50px;
  margin-bottom:10px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items: center;
}

.hori.reached{
  transform: translateX(0%);
  
}

.verti.reached{
  transform: translateY(0%);
  
}
.project-container > .grid-box:nth-child(2) .my-img {
  transition-delay:.3s;
}

.project-container > .grid-box:nth-child(3) .my-img {
  transition-delay:.6s;
}

.project-container > .grid-box:nth-child(4) .my-img {
  transition-delay:.9s;
}


.content-icon .icon:hover img{
  transform: scale(1.2);
  
}

.content-icon .icon img{
  width:24px;
  height:24px;
  margin:auto;
  transition: transform .2s ease-in-out ;
}


.project-container:hover .project-overlay {
  transform: translateY(0);
}

.project-container #box1, .project-container #box2 {
  grid-column :span 1;
    grid-column :span 1;
}

.project-container #box3 {
  grid-column :span 1;
    grid-row: span 2;
}

.project-container #box4 {
    grid-column :span 1;
    grid-column :span 2;
    
}

.timeline-container {
  position: relative;
  width: 100%;
  height: 800px;
  overflow:visible;
  z-index: 1;
  border-radius:30px;

}

.timeline {
  background: url('images/grass2.png') no-repeat center center/cover;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1px 0;
  z-index: 1;
  height: 700px;
  overflow: visible;
  transition: height 1s ease-out;
}



.timeline::before {
  content: '';
  position: absolute;
  width: 6px;
  height: 0%; /* Start with 0 height */
  background: #fe6e00;
  left: 50%;
  transition: height 1s ease-out; /* Transition for height change */
}

.timeline.expand::before {
  height: 93%; /* Expanded height */
}
.timeline-start{
  position:absolute;
  top:0px;
  left:50%;
  transform: translateX(-50%) translateY(-50%);
  width:20px;
  height:20px;
  border-radius:50%;
  background-color: #fe6e00 ;
  margin-left:3px;
  z-index:5;
}

.timeline-item {
  position: relative;
  width: 75%;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.timeline-item.right .timeline-icon,
.timeline-item.right .timeline-content {
  left: 50%;
  text-align: left;
}

.timeline-item.left .timeline-icon,
.timeline-item.left .timeline-content {
  right: 50%;
  text-align: right;
}

.timeline-item.right .timeline-content{
  margin-left:10px;
}

.timeline-item.left .timeline-content{
  margin-right:10px;
}

.timeline-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255)!important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.timeline-icon img {
  width: 80%;
}

.timeline-item:hover{
  cursor:pointer;
}

.timeline-item:hover .timeline-icon{
  transform: scale(1.2); 
}

.timeline-content {
  padding: 0 40px;
  text-align: center;
  position: absolute;
  top: 20px;
  transform: translateY(-0px);
}

.year {
  font-family: 'Abel', sans-serif;
  color: white;
  font-size: 20px;
}

.description {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: white;
  font-size: 20px;
  transform: translateY(-10px);
}

.contact-section {
  border-radius: 25px;
  border: 4px solid #fe6e00;
  background-size: 100% 100%;
  padding: 40px;
  color: #ffffff;
  margin-left: 50px;
  width: 60%;
  
}

.section-title {
  font-family: 'Rowdies', sans-serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid white;
  width: 0;
}

.contact-form .form-label {
  font-family: 'Montserrat', sans-serif;
  font-size: 25px;
  font-weight: 700;
  
}

.contact-form .form-control {
  background: linear-gradient(94deg, #FE6E00 29.76%, #FF3D00 74.49%);
  border: none;
  color: #ffffff;
  margin-bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.contact-form .form-control::placeholder {
  color: #ffffff;
}

.contact-form .form-control:focus {
  background: linear-gradient(94deg, #FE6E00 29.76%, #FF3D00 74.49%);
  color: #ffffff; /* Text color when focused */
  outline: none; 
}

.contact-form .btn-primary {
  background: linear-gradient(94deg, #FE6E00 29.76%, #FF3D00 74.49%);
  border: none;
  font-family: "Yatra One", sans-serif;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index:10;
  overflow:visible;
}

.contact-form{
  overflow:visible;
}

.contact-form .btn-primary:hover {
  animation: rotate-button 2s ease-in-out infinite;
  background-color: #fe6e0080;
}

@keyframes rotate-button {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
}


.send-icon {
  width: 20px;
  height: auto;
}

.contact-form textarea.form-control {
  height: auto;
}

.footer-section {
  background-color: rgba(254, 110, 0, 0.8);
  color: #ffffff;
  padding: 30px;
  text-align: left;
}

.footer-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 20px;
  color: White;
}

.footer-content {
  display: block;
}

.footer-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 10px;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.ellipse-icon {
  background-color: #ffffff;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipse-icon img {
  width: 50%;
  height: auto;
}

.footer-line {
  border-top: 2px solid #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.copyright {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 30px;
  margin-top: 20px;
  text-align: center;
}


@keyframes slideIn {
  from {
    transform: translateX(-200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease forwards;
}

/* Typing animation */
@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.typing {
  overflow: hidden; 
  border-right: 3px solid orange; 
  white-space: nowrap; 
  width: 0; 
  animation: typing 1.5s steps(40, end) forwards, blinko-caret .75s step-end infinite; 
}


.typing2 {
  overflow: hidden; 
  border-right: 3px solid white; 
  white-space: nowrap; 
  width: 0; 
  animation: typing2 1.5s steps(40, end) forwards, blink-caret .75s step-end infinite; 
}

@keyframes blinko-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@keyframes white-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}


/* Fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn .8s ease-in forwards;
}

/* Blinking caret effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black; }
}

@keyframes slideInRight {
  from {
    transform: translateX(200%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: slideInRight 1s ease forwards;
}

@keyframes slideUp {
  from {
    margin-top:150px;
    opacity: 0;
  }
  to {
    margin-top:25px;
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 1s ease forwards;
}

@keyframes slideUpFadeIn {
  from {
    margin-top:150px;
    opacity: 0;
  }
  to {
    margin-top:20px;
    opacity: 1;
  }
}

.slide-up-fade-in {
  animation: slideUpFadeIn 1s ease forwards;
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.my-career{
  overflow:visible;
}

.my-skills-card {
  /* Other existing styles... */
  transform: translateX(-100%); /* Initially position off-screen to the left */
  opacity: 0; /* Initially hidden */
}

/* Adjust the animation keyframes to include opacity */
@keyframes slideRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.my-skills-card.slide-in-right {
  animation: slideRight 0.5s ease-out forwards;
}

.my-skills-card:nth-child(1).slide-in-right {
  animation-delay: 0.2s;
}

.my-skills-card:nth-child(2).slide-in-right {
  animation-delay: 0.4s;
}

.my-skills-card:nth-child(3).slide-in-right {
  animation-delay: 0.6s;
}

.my-skills-card:nth-child(4).slide-in-right {
  animation-delay: 0.8s;
}

.my-skills-card:nth-child(5).slide-in-right {
  animation-delay: 1s;
}

.typed {
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid orange;
  width: 0%;
  animation: typing2 .5s steps(40, end) forwards, blink-caret .75s step-end infinite;
}

.i-am-a-passionate span{
  display:block;
  width:0%;
}

@keyframes typing2 {
  from { width: 0; }
  to { width: 320px; }
}

@keyframes typing3 {
  from { width: 0; }
  to { width: max-content }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}

@keyframes rotateIcon {
  0% { transform: rotate(0deg); }
  80% { transform: rotate(1440deg); } /* 4 rounds, fast rotation */
  100% { transform: rotate(1800deg); } /* additional slow rotation */
}

.my-skills-card:hover .my-skills-icon {
  animation: rotateIcon 5s ease-in-out forwards infinite;
}

/*Responsive*/
@media (max-width: 1277px) {

  .avatar-image, .wassup-im, .text-wrapper {
    display: none; 
  }

  .i-am-a-software{
    font-size:20px;
  }

  .i-am-a-passionate{
    width:100%;
    font-size:2vw;
    height:max-content
  }

  .skill-icon{
    width:100px;
  }

  .skill-card{
    height:300px;
    width:300px;
  }

}

@media (max-width: 1277px) {

  .skill-card{
    height:250px;
    width:250px;
  }

}

@media (max-width: 1130px) {


  .center-shape{
    left:70%;
  }

  .show-image{
    left:70%;
  }

  .i-am-a-software{
    width:50%;
  }

}

@media (max-width: 930px) {

  .project-content h3{
    font-size:20px;
  }

  .content-desc .desc {
    font-size:20px;
  }

  .content-icon .icon {
    width:40px;
    height:40px;
  }

  .content-icon .icon img{
    width:15px;
    height:15px;
  }

  .i-am-a-software{
    width:45%;
  }

  .skill-card-cont{
    flex-direction:column;
    align-items:center
  }

  .contact-section{
    width:90%;
    transform:translateX(-20px);
  }

}

@media (max-width: 730px) {

  .project-content h3{
    font-size:15px;
  }

  .content-desc .desc {
    font-size:15px;
  }

  .content-icon .icon {
    width:30px;
    height:30px;
  }

  .content-icon .icon img{
    width:10px;
    height:10px;
  }
  .i-am-a-software{
    width:45%;
  }

  .skill-card-cont{
    flex-direction:column;
    align-items:center
  }

}

@media (max-width: 700px) {

  .project-content h3{
    font-size:15px;
  }

  .content-desc .desc {
    font-size:8px;
  }

  .content-icon .icon {
    width:20px;
    height:20px;
  }

  .content-icon .icon img{
    width:5px;
    height:5px;
  }

  .timeline-item{
    width:95%;
  }
  .stats{
    width:200px;
    height:100px;
    padding-left:8%;
    padding-top:3%;
  }
  .my-num{
    font-size:40px;
  }
  .my-label{
    font-size:15px;
  }

  .i-am-a-software{
    font-size:15px;
    height:150px;
  }

  .description, .year{
    font-size:15px;
  }

  .description{
    transform:none;
  }
}

@media (max-width: 600px) {
  .project-container {
    grid-template-columns: 90%;
    grid-template-rows: repeat(auto-fill, 200px);
}

.grid-box{
    grid-column: span 1!important;
    grid-row: span 1!important;
}

.my-img{
  height:200px!important;
  width:100%;
}

.project-content h3{
  font-size:20px;
}

.content-desc .desc {
  font-size:15px;
}

.content-icon .icon {
  width:30px;
  height:30px;
}

.content-icon .icon img{
  width:12px;
  height:12px;
}
  .i-am-a-software{
    font-size:13px;
  }
}

@media (max-width: 550px) {

  .stat-box{
    display:none!important;
  }

  .i-am-a-software{
    display:none;
  }

  .center-shape{
    left:50%;
  }

  .show-image{
    left:50%;
  }

  .contact-section{
    width:90%;
    transform:translateX(-40px);
  }

  .description{
    font-size:10px;
  }

  .my-skills-icon-wrapper{
    width:100px;
    height:100px;
  }

  .text-wrapper-section{
    font-size:40px;
    text-align:center;
  }

  .my-skills-container{
    margin-left:auto;
    margin-right:auto;
  }

}







